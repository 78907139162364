import { Base } from "./base.js";
import { Winnetou } from "../node_modules/winnetoujs/src/winnetou.js";
import _strings from "./_strings.js";
import {
  footer,
  mainDivMobile,
  divider2,
  screens_pc,
  splash1_pc,
  splash_mobile,
  card,
  div_cards,
  review,
  testimonials,
  footerMobile,
} from "./constructos/commons.js";
import Log from "../libs_notLogged/logs.js";
import {
  icons_Twitter_X,
  icons_bellSolid,
  icons_chat2,
  icons_download,
  icons_jet,
  icons_messages,
  icons_notepad,
  icons_real,
  icons_search,
  icons_square_facebook,
  icons_square_twitter,
  icons_star_empty,
  icons_star_full,
  icons_star_half,
  icons_video,
} from "./constructos/_icons.js";
const l = new Log(true, "notLogged/pc.js");
// ----------------

Winnetou.updateTranslations(_strings).then(() =>
  new Main().testChromeVersion()
);

class Main extends Base {
  render() {
    //

    let aff = window["aff"] != "false";
    let ads = /ads/gi.test(window["from"]);

    let c = Winnetou.getMutable("country") || "BR";
    let lang = c === "BR" ? "pt" : "en";

    if (document.getElementById(this.mainDivBox?.ids.mainDivMobile))
      return;

    this.mainDivBox = mainDivMobile({
      action_googleplay: Winnetou.fx(() => {
        window.open(
          "https://play.google.com/store/apps/details?id=dating.a3menage.swing&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        );
      }),
      googleplay: "Usar aplicativo para android",
      action_facebook: Winnetou.fx(() => this.facebook_login()),
      facebook: _strings.facebook,
      action_twitter: Winnetou.fx(() => {
        location.href = "/twitter";
      }),
      twitter: _strings.twitter,
      top_text: _strings.top_text,
      action_register: Winnetou.fx(() => {
        Winnetou.navigate("/register");
      }),
      login: _strings.loginNotLogged,
      login_action: Winnetou.fx(() => {
        Winnetou.navigate("/login");
      }),
      or: _strings.or,
      register: _strings.register,
      lgpd: _strings.lgpd,
      action_lgpd: Winnetou.fx(() => {
        location.href = `/infocenter/lgpd/${lang}`;
      }),
      action_privacy: Winnetou.fx(() => {
        location.href = `/infocenter/privacy/${lang}`;
      }),
      action_terms: Winnetou.fx(() => {
        location.href = `/infocenter/terms/${lang}`;
      }),
      privacy: _strings.privacy,
      terms: _strings.terms,
      action_country: Winnetou.fx(() => {
        this.chooseCountry(true);
      }),
      country: _strings.changeCountry,
    }).create("#app");

    divider2({ fill: "#fff" }).create("#app");

    screens_pc().create("#app");

    splash_mobile({
      icon_facebook: icons_square_facebook().constructoString(),
      icon_twitter: icons_Twitter_X().constructoString(),
      facebook: Winnetou.fx(() => this.facebook_login()),
      twitter: Winnetou.fx(() => {
        location.href = "/twitter";
      }),
    }).create("#app");

    let out = testimonials().create("#app").ids.reviews_output;

    review({
      stars:
        icons_star_full().constructoString() +
        icons_star_full().constructoString() +
        icons_star_full().constructoString() +
        icons_star_full().constructoString() +
        icons_star_full().constructoString(),
      text: `"Melhor experiência em ${new Date().getFullYear()} em aplicativos de namoro."`,
      name: "Renato",
      age: "28",
      city: "São Paulo",
    }).create(out);

    review({
      stars:
        icons_star_full().constructoString() +
        icons_star_full().constructoString() +
        icons_star_full().constructoString() +
        icons_star_full().constructoString() +
        icons_star_full().constructoString(),
      text: `"Aqui o bixo pega! rsrsrs."`,
      name: "Souza",
      age: "21",
      city: "Rio de Janeiro",
    }).create(out);

    review({
      stars:
        icons_star_full().constructoString() +
        icons_star_full().constructoString() +
        icons_star_full().constructoString() +
        icons_star_full().constructoString() +
        icons_star_half().constructoString(),
      text: `"Eu e meu marido amamos fazer troca de casais há quase 10 anos já, mas não aguentávamos mais aplicativos travados e cheios de bugs, encontrar o A3Menage foi a melhor coisa que poderia ter nos acontecido!"`,
      name: "Clara e Ruy",
      age: "35 e 44",
      city: "Porto Alegre",
    }).create(out);

    review({
      stars:
        icons_star_full().constructoString() +
        icons_star_full().constructoString() +
        icons_star_full().constructoString() +
        icons_star_full().constructoString() +
        icons_star_full().constructoString(),
      text: `"A melhor coisa é não ter mais a decepção com homens na hora da cama, cansei de caras com rostinho bonito e ruim de cama, aqui eu posso escolher direitinho do tamanho que eu gosto antes de marcar um encontro, passar raiva na cama nunca mais!"`,
      name: "Jéssica",
      age: "36",
      city: "Salvador",
    }).create(out);

    review({
      stars:
        icons_star_full().constructoString() +
        icons_star_full().constructoString() +
        icons_star_full().constructoString() +
        icons_star_full().constructoString() +
        icons_star_empty().constructoString(),
      text: `"Não existe app perfeito de encontros, mas o a3 com certeza é o que mais se aproxima da perfeição!"`,
      name: "Emerson e Vanessa",
      age: "51 e 45",
      city: "Balneário Camboriú",
    }).create(out);

    let divCards = div_cards().create("#app").ids.inner;

    card({
      icon: icons_messages().constructoString(),
      title: "Mensagens gratuitas",
      text: "Diferente de outros sites de troca de casais, não pague nada no a3menage para enviar mensagens para quem você se interessou.",
    }).create(divCards);

    card({
      icon: icons_video().constructoString(),
      title: "Vídeos",
      text: "Veja vídeos reais dos usuários e tire qualquer dúvida se aquele perfil vale a pena o investimento mesmo ou não. Faça upload gratuito de seus próprios vídeos e veja sua caixa de mensagens lotar de convites para sair.",
    }).create(divCards);

    card({
      icon: icons_search().constructoString(),
      title: "Filtros de pesquisa",
      text: "Use nossos filtros de pesquisa gratuitos para encontrar aquele match perfeito. Veja as fotos e vídeos. Saiba quem visitou seu perfil. Envie mensagens. No A3 você tem tudo!",
    }).create(divCards);

    card({
      icon: icons_jet().constructoString(),
      title: "Navegação fluida",
      text: "Cansado dos sites de encontro que demoram para carregar? Nós também! Por isso no a3menage tudo carrega rápido pois usamos as mais novas tecnologias disponíveis para que você não perca tempo. Temos o mesmo estilo de navegação que o facebook, instagram e twitter utilizam.",
    }).create(divCards);

    card({
      icon: icons_bellSolid().constructoString(),
      title: "Notificações",
      text: "Enviamos notificações para o seu celular de tudo o que acontece em tempo real no seu perfil: novas mensagens, curtidas, comentários, novos seguidores e todas as outras interações que possam acontecer para que você não perca um encontro por ter deixado alguém especial no vácuo. Você pode escolher que tipo de notificações quer receber.",
    }).create(divCards);

    card({
      icon: icons_chat2().constructoString(),
      title: "Categorias",
      text: "Você pode criar categorias para organizar suas mensagens, dessa forma nunca perca de vista aqueles perfis que você tem interesse. Você também pode mandar conversas para o Limbo e nunca mais ser incomodado por aquela pessoa novamente.",
    }).create(divCards);

    card({
      icon: icons_notepad().constructoString(),
      title: "Bloco de notas",
      text: "No a3menage você terá seu próprio bloco de notas sigiloso. Nele você poderá anotar tudo o que for importante: perfis que você gosta, datas de encontros, experiências, números de whatsapp, emails e tudo mais o que você achar importante.",
    }).create(divCards);

    card({
      icon: icons_real().constructoString(),
      title: "Segurança",
      text: "O A3 tem um arsenal de ferramentas para verificar a reputação de um perfil: recomendações como real, notas de usuários e depoimentos. Dessa forma você pode marcar um encontro com tranquilidade.",
    }).create(divCards);

    card({
      icon: icons_download().constructoString(),
      title: "Aplicativo",
      text: "Disponibilizamos o aplicativo para android e iphone. Dessa forma você tem mais praticidade na hora de acessar o A3.",
    }).create(divCards);

    footerMobile({
      line1: _strings.line1_footer,
      line2: _strings.line2_footer,
      year: new Date().getFullYear(),
    }).create("#app");

    // let lgpd_ = lgpd(
    //   {
    //     action: Winnetou.fx(() => {
    //       Winnetou.select(lgpd_.ids.lgpd).remove();
    //     }),
    //     button: _strings.accept,
    //     text: _strings.lgpdText,
    //     link: _strings.lgpdLink,
    //   },
    //   { identifier: "div" }
    // ).create("#app");

    // pré carrega o bundle do logged
    let preloadLink = document.createElement("link");
    preloadLink.href = "/logged/release/mobile/winnetouBundle.min.js";
    preloadLink.rel = "preload";
    preloadLink.as = "script";
    document.head.appendChild(preloadLink);

    setTimeout(() => {
      // pré carrega o socket
      let preloadLink = document.createElement("link");
      preloadLink.href = "/socket.io/socket.io.js";
      preloadLink.rel = "preload";
      preloadLink.as = "script";
      document.head.appendChild(preloadLink);
    }, 5000);
  }

  updateBrowserMessage() {
    let foo = confirm(_strings.updateBrowserMessageApp);

    if (foo) {
      window.open(
        "https://play.google.com/store/apps/details?id=com.android.chrome"
      );
    }
  }
}
